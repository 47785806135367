<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>Webhook Message</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                A webhook message is sent to webhook receivers to notify your app of new activity as it happens.
                            </p>

                            <template v-if="webhookMessage">
                                <p class="mb-2">Label: {{ webhookMessage.label }}</p>
                                <p class="mb-2">Content: {{ webhookMessage.content }}</p> <!-- TODO: textarea, or pre tag? -->
                                <p class="mb-2">Created on: {{ formatDate(webhookMessage.created_on) }}</p>
                                <p class="mb-2" v-if="webhookMessage.status">Status: {{ messageStatusDisplay(webhookMessage.status) }}</p>
                                <p class="mb-2" v-if="webhookMessage.status_on">Status on: {{ formatDate(webhookMessage.status_on) }}</p>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    components: {
    },
    data: () => ({
        organization: null,
        webhookMessage: null,
        error: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isViewReady() {
            return this.webhookMessage !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditHostnameFormComplete() {
            return this.editableHostname;
        },
    },
    methods: {
        async loadWebhookMessage() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadWebhookMessage: true });
                const response = await this.$client.organization(this.$route.params.organizationId).webhookMessage.get({ id: this.$route.params.webhookMessageId });
                console.log(`loadWebhookMessage: response ${JSON.stringify(response)}`);
                if (response) {
                    this.webhookMessage = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadWebhookMessage: false });
            }
        },
        messageStatusDisplay(status) {
            // TODO: message delivery status like 'delivered', 'failed', 'cancelled', 'pending', etc.
            if (status === 'delivered') {
                return 'Delivered';
            }
            if (status === 'failed') {
                return 'Failed';
            }
            if (status === 'cancelled') {
                return 'Cancelled';
            }
            if (status === 'pending') {
                return 'Pending';
            }
            if (status === 'queue') {
                return 'Queue';
            }
            return status;
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
    },
    mounted() {
        this.loadWebhookMessage();
    },
};
</script>
